import React from "react";
import { Card, Col, Divider, Row } from "antd";
import Image from "../img";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const { Meta } = Card;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 8
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 8
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};

export const Inicio = (props) => {
  return (
    <>
      <br />
      <div id="inicio1" className="text-center" style={{ /*backgroundColor: 'white',*/ marginTop: '40px' }}>
        <div className="container">
          {/* <div className="col-xs-12 col-md-12" style={{ alignItems: 'center', textAlign: 'center' }}>
            <h2 className="section-title" style={{ color: '#ff711a' }}><b>"Somos una empresa dedicada a dar SOLUCIONES INDUSTRIALES en soldadura y corte."</b></h2>
          </div> */}
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>SOMOS DISTRIBUIDORES OFICIALES</h2>
          </div>
          <div className="row">
            {props.data?.marcas
              ? props.data.marcas.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-12 col-md-3">
                  <Card
                    id="iniciocard"
                      key={`${d.title}-${i}`}
                      hoverable
                      cover={<img className={d.clase !== "" ? d.clase : "imagenInicio"} alt={d.image.alt_image} src={require(`../assets/images/${d.image.src_image}`)} />}
                      foot
                      className={d.claseCard}
                    >
                    {/* <Meta className="metaMarca" title={d.title}/> */}
                  </Card>
                </div>
              ))
              : "Loading..."}
          </div>
        </div>
      </div>
      <br />
      <Divider />
      <br />
      {/* --------------- NOSOTROS --------------- */}
      {/* <div id="distribuidoroficial" style={{ backgroundImage: require('../assets/empresa/sudestefondo.png') }}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-12" style={{ alignItems: 'center', textAlign: 'center' }}>
              <h2 className="section-title" style={{ color: '#ff711a' }}><b>"Somos una empresa dedicada a dar SOLUCIONES INDUSTRIALES en soldadura y corte."</b></h2>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div> */}
      <br />
    </>
  );
};
