import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Inicio } from "./components/inicio";
import { Contact } from "./components/contact";
import { Nosotros } from "./components/nosotros";
import { Solutions } from "./components/solutions";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
// import Progress from "./components/404";
import "./App.css";
import { Divider } from "antd";
import { Especializado } from "./components/sepecialist";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 300,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <title>DS Ingeniería y Servicios</title>
      {/* <Progress /> */}
      <Navigation />
      <br/>
      <br/>
      <Header data={landingPageData} />
      <br/>
      <Divider/>
      <br/>
      <Solutions data={landingPageData} />
      <br/>
      <br/>
      <Inicio data={landingPageData} />
      <br/>
      <Divider/>
      <br/>
      <Especializado data={landingPageData} />
      <br/>
      <Divider/>
      <br/>
      <Nosotros data={landingPageData} />
      <br/>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
