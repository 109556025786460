import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export const Header = (props) => {
  return (
    <header id="inicio">
      <Carousel
        // autoPlay
        infiniteLoop
        showArrows={true}
        showIndicators={false}
        showStatus={false}
      >
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-md-offset-1 intro-text">
                  <h1>
                    {props.data?.Header ? props.data.Header.title : "Loading"}
                    <span></span>
                  </h1>
                  {/* <p>{props.data?.Header ? props.data.Header.paragraph : "Loading"}</p> */}
                  <a
                    href="#soluciones"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Conocer más
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="intro3">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-md-offset-1 intro-text">
                  <h1>
                    {props.data?.Header ? props.data.Header.title : "Loading"}
                    <span></span>
                  </h1>
                  {/* <p>{props.data?.Header ? props.data.Header.paragraph : "Loading"}</p> */}
                  <a
                    href="#soluciones"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Conocer más
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>

    </header>
  );
};
