import { Col, Grid, Row } from "antd";
import React from "react";
import utils from "../utils";

const { useBreakpoint } = Grid;
export const Solutions = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  return (
    <>
      <div id="soluciones" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Nuestras Soluciones</h2>
            <p>Nuestras soluciones se adaptan a cada desafío de soldadura y corte, buscando maximizar su productividad y rentabilidad a través de la mejora continua de procesos y recursos.</p>
          </div>
          <div className="row">
            {props.data?.Services
              ? props.data.Services.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4" id="services-card">
                  {/* {" "} */}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
              : "loading"}
          </div>
        </div>
      </div>
      {/* <Divider>2DA OPCION</Divider> */}
      {/* ------------------ opción anterior ------------------ */}
      {/* <div id="solutions2" className="text-center">
        <div className="container">
          <div className="ml-8 mr-8 mt-10">
            <div className="">
              <div className="col-12 col-md-3 mb-3">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-2 text-2xl">NUESTRAS SOLUCIONES</h4>
                    <p className="card-text">
                      Nuestras soluciones se adaptan a cada desafío de Soldadura y Corte, buscando maximizar su Productividad y Rentabilidad a través de la mejora continua de procesos y recursos.
                    </p>
                  </div>
                </div>
              </div>
              {props.data?.Services
                ? props.data.Services.map((d, i) => (
                  <div className="col-12 col-md-3 mb-3">
                    <div className="card border ppbox" id="cardold">
                      <div className="card-body">
                        <p className="text-center text-rose-950"><i className={`${d.icon} text-center`} ></i></p>
                        <h4 className="card-title mb-2 text-2xl">{d.name}</h4>
                        <p className="card-text" style={{ fontWeight: '400' }}>{d.text}</p>
                      </div>
                    </div>
                  </div>
                ))
                : "Loading..."}
            </div>
          </div>
        </div>
      </div>
      <br /> */}
      {/* ------------------ Distribuidor oficial ------------------ */}
      {/* <div id="postventa" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Servicio Post Venta</h2>
          </div>
          <p className='text-center' style={{ fontWeight: 270, fontSize: '18px' }}>
            El servicio de Post Venta tiene por objetivo primordial satisfacer y orientar a nuestros clientes.
            <br />
            Este valor agregado se pone de manifiesto a través de los servicios de seguimiento, reparación y mantención de equipos, asesorados por técnicos profesionales altamente capacitados que avalan las reparaciones en el menor tiempo, con el mejor costo de mantenimiento y con el propósito de brindar al cliente la calidad, la confiabilidad y el respaldo de una marca líder.
            <br />
            <br />
            <b>OFRECEMOS:</b>
          </p>
          <ul className="" style={{ fontWeight: 270, fontSize: '18px' }}>
            <li>Garantía</li>
            <li>Repuestos legítimos y originales</li>
            <li>Asistencia, reparación y mantenimiento</li>
          </ul>
        </div>
      </div> */}
    <br/>
      {/* ------------------ Nuestras Marcas ------------------ */}
      <div id="marcas">
        {/* <div className="container">
          <div className="row" style={{ marginBottom: '30px' }}>
            <br />
            <h2 className='text-center'>SOMOS DISTRIBUIDORES OFICIALES</h2>
            <h4 className='text-center'>Somos distribuidores oficiales y servicio técnico especializado de las marcas de primer nivel para cada proceso de soldadura y corte.</h4>
          </div>
          <Row>
          {props.data.marcas && props.data.marcas.map((marca, index) => {
            return (
                <Row key={index}>
                  {!utils.esPar(index) && !isMobile &&
                    <Col span={12}>
                      <div className='fill'>
                        <img src={require(`../assets/images/${marca.thumb_src}`)} alt={marca.thumb_src + index} key={index} style={{width:'100%'}}/>
                      </div>
                    </Col>
                  }
                    <Col xxs={24} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <img src={require(`../assets/images/${marca.image.src_image}`)} alt={marca.image.alt_image} style={{ width: 200, height: 100, verticalAlign: 'middle', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                      <br />
                      <h2 level={3} className="mb-3 text-center">{marca.title}</h2>
                      <p className="text-justify descripcionMarca">{marca.description}</p>
                    </Col>
                  {utils.esPar(index) && !isMobile &&
                    <Col span={12}>
                      <div className='fill'>
                        <img src={require(`../assets/images/${marca.thumb_src}`)} alt={marca.thumb_src + index} key={index} style={{width:'100%'}}/>
                      </div>
                    </Col>
                  }
                </Row>
            )
          })}
          </Row>
        </div> */}
      </div>
    </>
  );
};
