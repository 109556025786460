import React from "react";
import { Divider, Grid, Card, Row, Col, Avatar } from 'antd';
import { WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import utils from "../utils";
const { useBreakpoint } = Grid;

const texto = "https://api.whatsapp.com/send/?phone=";
export const Contact = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const cnColM = "col-start-1 col-end-12";
  return (
    <div>
      <div id="contact" style={{ backgroundColor: '#4f4c49 !important' }}>
        {/* style={{ backgroundColor: '#4f4c49' }} */}
        <div className="container">
          <br />
          <div className="grid gap-2 ml-10 mr-10">
            <div >
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13937.694754322672!2d-59.67394672753907!3d-29.152184462428544!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x944eb1f31a0db0d1%3A0xc1585d5a0b882409!2sDS%20Ingenier%C3%ADa%20y%20Servicios%20en%20Soldadura%20y%20Corte!5e0!3m2!1ses!2sar!4v1714774583572!5m2!1ses!2sar"
                width={"100%"}
                height={"350"}
                title='DS Ingenieria y Servicios'
                // style="border:0;" 
                allowFullScreen="true"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <br />
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} style={{ margin: '10px 0px' }}>
              <Card
              className="hidden"
                style={{
                  width: '100%',
                }}
              >
                <Card.Meta
                  avatar={<Avatar src={require('../assets/Icons/location.png')} />}
                  title="Ubicación"
                  bordered={false}
                  description={
                    <>
                      <Row><span>Reconquista - Santa Fe</span></Row>
                      <Row><span>DISTRITO IT - Calle España N° 2676</span></Row>
                      <Row><span>CP 3560</span></Row>
                      {/*  */}
                    </>
                  }
                />

              </Card>
            </Col>
            <br />
            <div className={isMobile ? cnColM : "col-start-1 col-end-12"}>
              <Row>
                <Col span={24} style={{ alignItems: 'center',textAlign:'center' }}>
                  <h2 className='section-title'>Contactos</h2>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} style={{ margin: '10px 0px' }}>
                  <Card title="Administración" bordered={false}>
                    <>
                      <Row>
                        <a style={{color:'black'}} href="mailto:administracion@dsingenieria.com.ar?subject=Contacto&body=Buen día. Quiero comunicarme con administración." target='_blank' rel="noreferrer">
                          <MailOutlined /> administracion@dsingenieria.com.ar
                        </a>
                      </Row>
                      <Row>
                        <a style={{color:'black'}} href={`${texto}5493482370747&text=Quiero%20solicitar%20una%20cotizaci%C3%B3n.`} target="_blank" rel="noreferrer">
                          <WhatsAppOutlined /> 3482-370747
                        </a>
                      </Row>
                    </>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} style={{ margin: '10px 0px' }}>
                  <Card title="Cotización de equipos y consumibles" bordered={false}>
                    <>
                      <Row>
                        <a style={{color:'black'}} href="mailto:ventas@dsingenieria.com.ar?subject=Cotización&body=Buen día. Quiero solicitar una cotización." target='_blank' rel="noreferrer">
                          <MailOutlined /> ventas@dsingenieria.com.ar</a>
                      </Row>
                      <Row>
                        <a style={{color:'black'}} href={`${texto}5493482621794&text=Hola.%20Quiero%20solicitar%20una%20cotizaci%C3%B3n.`} target="_blank" rel="noreferrer">
                          <WhatsAppOutlined /> 3482-621794
                        </a>
                      </Row>
                    </>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} style={{ margin: '10px 0px' }}>
                  <Card title="Automatización Industrial" bordered={false}>
                    <>
                      <Row>
                        <a style={{color:'black'}} href="mailto:ventas@dsingenieria.com.ar?subject=Cotización&body=Buen día. Quiero solicitar una cotización." target='_blank' rel="noreferrer">
                          <MailOutlined /> ventas@dsingenieria.com.ar</a>
                      </Row>
                      <Row>
                        <a style={{color:'black'}}
                          href={`${texto}5493425380674&text=Hola.%20Quiero%20solicitar%20una%20cotizaci%C3%B3n.`}
                          target="_blank"
                          rel="noreferrer"><WhatsAppOutlined /> 342-5380674</a>
                      </Row>
                    </>
                  </Card>
                </Col>
              </Row>
              <Divider />
              <br />
            </div>
          </div>
          {/* <br />
          <div style={{ alignItems: 'center',textAlign:'center' }}>
            <div><Button type="primary"
              onClick={() => window.open(`${texto}5493482621794&text=Hola.%20Quiero%20solicitar%20una%20cotizaci%C3%B3n.`)}
            >Contacto</Button></div>
          </div>
          <br/>
          <div style={{ alignItems: 'center',textAlign:'center' }}>
            <div><Button type="link"
              icon={<FacebookOutlined style={{ fontSize: 32, color: 'black' }} />}
              onClick={() => window.open("https://www.instagram.com/ds.ingenieriayservicios/", "_blank")}
            >
            </Button>
              <Button type='link' icon={<InstagramOutlined style={{ fontSize: 32, color: 'black' }} />}
                onClick={() => window.open("https://www.instagram.com/ds.ingenieriayservicios/", "_blank")}
              >
              </Button>
            </div>
          </div>
          <br />
          <br /> */}
          <Divider/>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={"https://www.instagram.com/ds.ingenieriayservicios/"} target="_blank" rel="noreferrer">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.instagram.com/ds.ingenieriayservicios/"} target="_blank" rel="noreferrer">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={`${texto}5493482621794&text=Hola.%20Quiero%20solicitar%20una%20cotizaci%C3%B3n.`} target="_blank" rel="noreferrer">
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024. Design by{" "}
            <a href="http://www.ipsistemas.com.ar" target='_blank' rel="noreferrer">
              Ip.Sistemas
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
